module.exports = {
  siteUrl: 'https://blog.futurefamily.com', // Site domain. Do not include a trailing slash!

  postsPerPage: 40, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: 'Future Family - Blog', // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: 'The official news and blog for Future Family — your source for modern fertility care.', // This allows an alternative site description for meta data for pages.

  shareImageWidth: 1000, // Change to the width of your default share image
  shareImageHeight: 523, // Change to the height of your default share image

  shortTitle: 'FF Blog', // Used for App manifest e.g. Mobile Home Screen
  siteIcon: 'favicon.png', // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: '#ffffff', // Used for Offline Manifest
  themeColor: '#00bdbb' // Used for Offline Manifest
};
